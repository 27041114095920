<template>
  <footer class="app-footer">
    <div class="logo-section">
      <img src="@/assets/logo_big.png" alt="Company Logo" class="company-logo" />
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
};
</script>

<style scoped>
.app-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
  width: 100%;
  box-sizing: border-box; /* Ensure the padding is included in the width */
  border-top: 1px solid #ddd; /* Added border at the top */
}

.logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-logo {
  width: 150px;
  height: auto;
}
</style>
