<template>
  <div class="header-container">
    <header class="header">
      <img :src="logoSrc" alt="Logo" class="logo" @click="navigateTo('LandingPage')" />
      <nav class="nav">
        <a @click.prevent="navigateTo('LandingPage')">Projects</a>
      </nav>
    </header>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AppHeader',
  setup() {
    const router = useRouter();
    const navigateTo = (routeName) => {
      router.push({ name: routeName });
    };

    const isMobile = ref(window.innerWidth <= 768);
    const logoSrc = ref(require('@/assets/logo_big.png'));

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
      logoSrc.value = isMobile.value ? require('@/assets/logo_small.png') : require('@/assets/logo_big.png');
    };

    onMounted(() => {
      window.addEventListener('resize', updateIsMobile);
      updateIsMobile();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateIsMobile);
    });

    return { navigateTo, logoSrc };
  }
};
</script>

<style scoped>
.header-container {
  position: relative;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  background-color: #fff;
  padding: 0 20px; 
  height: 70px;
}

.logo {
  height: 50px;
  cursor: pointer;
}

.nav {
  display: flex;
  align-items: center;
  gap: 20px; 
}

.nav a {
  text-decoration: none;
  color: #333; 
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 5px; 
  transition: background-color 0.3s, color 0.3s;
}

.nav a:hover {
  background-color: #f0f0f0; 
  color: #000; 
  cursor: pointer;
}
</style>
