import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'; // Add this line
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas, fab); // Add fab to the library

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(router).mount('#app');
