<template>
  <div id="app">
    <WideHighlightContainer />
    <ProjectTilesContainer />
  </div>
</template>

<script>
import WideHighlightContainer from './WideHighlightContainer.vue';
import ProjectTilesContainer from './ProjectTilesContainer.vue';

export default {
  name: 'LandingPage',
  components: {
    WideHighlightContainer,
    ProjectTilesContainer,
  },
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}
</style>
