<template>
  <div class="product-tile">
    <div class="image-container">
      <img :src="product.image_url" :alt="product.name" class="product-image" />
    </div>
    <div class="product-info">
      <h3 class="product-name">{{ product.name }}</h3>
      <p class="product-role">{{ product.role }}</p>
      <hr class="separator" />
      <p class="product-description">{{ product.description }}</p>
    </div>
    <div class="button-container">
      <button class="info-button" @click="openProjectUrl">More Info</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductTile',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openProjectUrl() {
      window.open(this.product.project_url, '_blank');
    }
  }
};
</script>

<style scoped>
.product-tile {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: 300px; /* Fixed width for uniformity */
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 10px;
  height: 100%; /* Ensure the tile takes the full height available */
}

.product-tile:hover {
  transform: scale(1.05);
}

.image-container {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensure image fits within the container */
}

.product-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  height: 100%;
}

.product-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px; /* Reduced margin between title and role */
}

.product-role {
  font-size: 14px;
  color: #777;
  margin: 0; /* Removed margin for role */
}

.separator {
  width: 100%;
  border: 0;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0; /* Margin for separator */
}

.product-description {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
  flex-grow: 1; /* Ensure the description grows to fill available space */
}

.button-container {
  display: flex;
  justify-content: center;
  padding-bottom: 10px; /* Add gap between button and bottom of the tile */
}

.info-button {
  background-color: #000;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.info-button:hover {
  background-color: #444;
}
</style>
