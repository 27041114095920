<template>
    <div class="project-tiles-container">
      <div class="slider">
        <ProductTile
          v-for="(project, index) in projects"
          :key="index"
          :product="project"
        />
      </div>
    </div>
  </template>
  
  <script>
  import ProductTile from './ProductTile.vue';
  import products from '@/assets/products.json';
  
  export default {
    name: 'ProjectTilesContainer',
    components: {
      ProductTile,
    },
    data() {
      return {
        projects: products.products,
      };
    }
  };
  </script>
  
  <style scoped>
  .project-tiles-container {
    overflow-x: auto;
    display: flex;
    padding: 20px 0;
    border-top: 1px solid #ddd; /* Add border at the top */
  }
  
  .slider {
    display: flex;
    flex-direction: row;
    gap: 20px; /* Add gap between cards */
  }
  </style>
  