import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/components/LandingPage.vue';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: {
      title: 'Erdem Karataş - Computer Science Engineer',
      metaTags: [
        {
          name: 'description',
          content: 'Welcome to the personal portfolio of Erdem Karataş, a Computer Science Engineer specializing in AI, machine learning, and web development. Explore my projects, experience, and contact information.'
        },
        {
          property: 'og:title',
          content: 'Erdem Karataş - Computer Science Engineer'
        },
        {
          property: 'og:description',
          content: 'Welcome to the personal portfolio of Erdem Karataş, a Computer Science Engineer specializing in AI, machine learning, and web development. Explore my projects, experience, and contact information.'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'keywords',
          content: 'Erdem Karataş, Computer Science Engineer, AI, Machine Learning, Web Development, Portfolio'
        },
        {
          name: 'author',
          content: 'Erdem Karataş'
        }
      ]
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
